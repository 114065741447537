// import { PromptResponseTypes } from "@interfaces/api/dashboard/prompts"
import { ConfigProvider, Input, Table, theme } from "antd"
import { PromptResponseTypes } from "@interfaces/api/dashboard/prompts";
import React from "react";
import { useAppSelector } from "@store/store";
import { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import type { ColumnType } from "antd/es/table";
import formatNumberToIndianStyle from "@functions/reports/indianCurrencyFormat";
import { AnyObject } from "antd/es/_util/type";

interface GenerateTableDataTypes {
    response: PromptResponseTypes
}


const GenerateTable: React.FC<GenerateTableDataTypes> = ({ response }) => {
    const { columns, data } = response;

    const isNumericColumn = (colIndex: number, colKey: string): boolean => {
        const firstValue = data[colIndex]?.[colKey];
        return typeof firstValue === 'number' || !isNaN(Number(firstValue));
    };

    const dataSource = data.map((row) => {
        return {
            key: row.id,
            ...row
        }
    })
    // console.log(columns, data, dataSource);



    const themeMode = useAppSelector((state) => state.theme.themeMode)

    const darkModeColors = {
        colorBorder: "#1B41E9",
        colorBgBase: "#000000",
    }
    const lightModeColors = {
        colorBorder: "#d9d9d9",
        colorBgBase: "#ffffff",
    }

    const totalWidth = columns.length * 150;

    const getColumnProps = (dataIndex: string, isNumeric: boolean): ColumnType<AnyObject> => {
        if (isNumeric) {
            return {
                sorter: (a, b) => Number(a[dataIndex]) - Number(b[dataIndex]),
                sortDirections: ['ascend', 'descend'] as SortOrder[],
            };
        } else {
            return {
                sorter: (a, b) => a[dataIndex] > b[dataIndex] ? -1 : a[dataIndex] < b[dataIndex] ? 1 : 0,
                // sortDirections: ['ascend', 'descend'] as SortOrder[],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
                    <div className="p-3">
                        <Input
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={e => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                        <div>
                            <button
                                type="button"
                                onClick={() => {
                                    clearFilters && clearFilters();
                                    confirm({ closeDropdown: false });
                                }}
                                disabled={selectedKeys.length === 0}
                                className="border border-dark-theme-grey rounded-xl disabled:text-gray-300 disabled:border-fireai-grey px-2"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                ),
                onFilter: (value, record) =>
                    record[dataIndex]
                        ? (record[dataIndex] as string).toString().toLowerCase().includes((value as string).toLowerCase())
                        : false,

            };
        }
    };

    return (
        <div className={themeMode === "dark" ? "dark-theme" : ""}>
            <ConfigProvider
                theme={{
                    algorithm: themeMode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
                    token: themeMode === "light" ? lightModeColors : darkModeColors,
                }}
            >
                <Table
                    className="custom-table"
                    dataSource={dataSource}
                    columns={
                        columns.map((col, colIndex) => ({
                            ...col,
                            render: (value: number | string) => {
                                // console.log(col);
                                const isnum = isNumericColumn(colIndex, col.dataIndex as string);
                                // percentage sign formatted in frontend for now...
                                return (
                                    <div className={`w-full ${isnum ? 'text-right' : 'text-left'}`}>
                                        {!isnum ?
                                            value === "NA" || value === 'NaN' || value === 0 ? 0 : value
                                            :
                                            (col.title as string).includes("%") ? value : formatNumberToIndianStyle(Number(value))}
                                        {(col.title as string).toLowerCase().includes('percent') ? '%' : ''}
                                    </div>
                                );
                            },
                            onCell: () => ({
                                style: {
                                    padding: '5px',
                                    border: themeMode === "light" ? '1px solid #B7B7B7' : '1px solid #202222',
                                }
                            }),
                            onHeaderCell: () => ({
                                style: {
                                    padding: '10px 5px',
                                    border: themeMode === "light" ? '1px solid #B7B7B7' : '1px solid #202222',
                                    backgroundColor: themeMode === "light" ? "#EAEAEA" : "",
                                    whiteSpace: 'nowrap', 
                                    overflow: 'hidden',   
                                    textOverflow: 'ellipsis', 
                                    maxWidth: '120px'
                                }
                            }),
                            ...getColumnProps(col.dataIndex as string, isNumericColumn(colIndex, col.dataIndex as string)),
                            ellipsis: true 
                        }))
                    }
                    pagination={{ pageSize: 150 }}
                    scroll={{ y: 300, x: totalWidth }}
                />

            </ConfigProvider>
        </div>
    );
};

export default GenerateTable