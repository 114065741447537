export default function formatNumberToIndianStyle(num: number | string): string {
    const isNegative = typeof num === 'number' && num < 0;
    const numStr = Math.abs(Number(num)).toString().split(".");
    const integerPart = numStr[0];

    let lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== '') {
        lastThreeDigits = ',' + lastThreeDigits;
    }

    const formattedNumber = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

    return isNegative ? '-' + formattedNumber : formattedNumber;
}